import { DesignType } from '#gql/default';
import type {
  FilterCategory,
  FilterCategoryName,
} from '@/types/list-page';

const filterCategoryNames = [
  'product',
  'topic',
  'designColor',
  'technique',
  'style',
  'recipient',
  'occasion',
  'room',
  'productColor',
  'gender',
] as const;

const filterCategories: FilterCategory[] = [
  {
    isExpanded: true,
    isRelevantForSearchIndex: true,
    name: 'product',
  },
  {
    isExpanded: true,
    isRelevantForSearchIndex: true,
    name: 'topic',
  },
  {
    isExpanded: true,
    isRelevantForSearchIndex: true,
    name: 'designColor',
  },
  {
    isExpanded: false,
    isRelevantForSearchIndex: true,
    name: 'technique',
  },
  {
    isExpanded: false,
    isRelevantForSearchIndex: true,
    name: 'style',
  },
  {
    isExpanded: false,
    isRelevantForSearchIndex: true,
    name: 'recipient',
  },
  {
    isExpanded: false,
    isRelevantForSearchIndex: true,
    name: 'occasion',
  },
  {
    isExpanded: false,
    isRelevantForSearchIndex: true,
    name: 'room',
    products: [
      'acrylglas',
      'acrylglas-gerahmt',
      'aludibond',
      'aludibond-gerahmt',
      'forexplatte',
      'forexplatte-gerahmt',
      'fototapete',
      'hahnemuehle',
      'leinwand',
      'leinwand-schattenfuge',
      'poster',
      'poster-alurahmen',
      'poster-holzrahmen',
      'poster-kunststoffrahmen',
    ],
  },
  {
    isExpanded: false,
    isRelevantForSearchIndex: false,
    name: 'productColor',
    products: [
      'bag-tote',
      'bib-baby',
      'hoodie',
      'hoodie-kid',
      'mug-enamel',
      'phone-cover-iphone',
      'phone-cover-samsung',
      'romper-long-sleeve-baby',
      'romper-short-sleeve-baby',
      'sweatshirt',
      'sweatshirt-baby',
      'sweatshirt-kid',
      't-shirt',
      't-shirt-baby',
      't-shirt-fitted-woman',
      't-shirt-kid',
    ],
  },
  {
    isExpanded: false,
    isRelevantForSearchIndex: true,
    name: 'gender',
  },
] as const;

const AMOUNT_FOR_SHOW_LESS_FILTERS_MAIN = 5;
const AMOUNT_FOR_SHOW_LESS_FILTERS_SUB = 10;
const DESIGN_TYPE = 'designType';
const SEARCH_PARAM = 'q';

const DESIGN_TYPE_FILTER_TABS : {
  filterkey: DesignType;
  name: string;
  showInformationIcon: boolean;
}[] = [
  {
    filterkey: DesignType.ALL,
    name: 'allDesigns',
    showInformationIcon: false,
  },
  {
    filterkey: DesignType.CREATE,
    name: 'create',
    showInformationIcon: true,
  },
  {
    filterkey: DesignType.ART,
    name: 'art',
    showInformationIcon: true,
  },
];

export {
  AMOUNT_FOR_SHOW_LESS_FILTERS_MAIN,
  AMOUNT_FOR_SHOW_LESS_FILTERS_SUB,
  DESIGN_TYPE,
  DESIGN_TYPE_FILTER_TABS,
  filterCategories,
  type FilterCategoryName,
  filterCategoryNames,
  SEARCH_PARAM,
};
